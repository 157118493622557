import logo from './logo.svg';
import './App.css';
import { useState } from 'react';

function App() {
  const [showContact, setShowContact] = useState(false)
  const [showPortal, setShowPortal] = useState(false)
  const [error, setError] = useState(null)

  const onSubmit = () => {
    setError("This device (browser) is not recognized. Due to security reasons, we cannot log you in on this device (browser). Please try logging in using another device (browser) or contact our support team. Thanks!")

  }

  return (
    <div className="app-main-container">
      <div className='app-center-container'>
        <h1>TetraSync, Inc.</h1>
        <div className='app-link-row'>
          <div onClick={() => setShowPortal(true)}>// Investors' Portal</div>
          <div onClick={() => setShowContact(true)}>// Contact</div>
        </div>
      </div>
      <div className='app-modal' style={{"display": showContact ? "block":"none"}}>
        <div className='app-cancel-button' onClick={() => setShowContact(false)}>X</div>
        <h1>Contact:</h1>
        <hr/>
        <p><i>Email:</i> hello@aromasense.ai</p>
        <p><i>LinkedIn:</i> <a style={{color: '#E24242'}} href="https://www.linkedin.com/company/tetrasync-inc/" target='_blank'>tetrasync-inc</a></p>
        <p><i>Mailing Address:</i> <br/>228 Park Ave S PMB 32706, <br/>New York, New York 10003, US</p>
      </div>

      <div className='app-modal' style={{"display": showPortal ? "block":"none"}}>
        <div className='app-cancel-button' onClick={() => setShowPortal(false)}>X</div>
        <h1>Investors' Portal Login:</h1>
        <hr/>
        <div className='app-portal-container'>
          <label>Investor UID: </label>
          <input />
          <span className='app-portal-submit-button' onClick={onSubmit}>Go</span>
          {error && <p style={{color: 'red'}}>{error}</p>}
        </div>
      </div>

    </div>
  );
}

export default App;
